import { FunctionComponent, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { H4, Text } from 'components/Typography/Typography';
import { ActionButton } from 'components/Buttons/ActionButton';
import { useRouter } from 'next/router';
import { scrollTop } from 'utils';

interface ICookies {
  handleDeclineClick: () => void;
  handleAcceptClick: () => void;
}

export const Cookies: FunctionComponent<ICookies> = ({
  handleDeclineClick,
  handleAcceptClick,
}) => {
  const router = useRouter();

  const handleLinkClick = useCallback(
    (path) => {
      router.push(path);
      scrollTop();
    },
    [router, scrollTop],
  );

  return (
    <CookiesWrapper>
      <H4>THIS WEBSITE USES COOKIES</H4>
      <Text>
        We use cookies to provide you with the best possible experience. They
        also allow us to analyze user behavior in order to constantly improve
        the website for you. You can read more about our{' '}
        <span
          aria-hidden="true"
          onClick={() => handleLinkClick('/cookie-policy')}
        >
          Cookie Policy
        </span>{' '}
        and{' '}
        <span
          aria-hidden="true"
          onClick={() => handleLinkClick('/privacy-policy')}
        >
          Privacy Policy
        </span>
        .
      </Text>
      <ButtonsWrapper>
        <ActionButton onClick={handleDeclineClick}>Decline</ActionButton>
        <ActionButton onClick={handleAcceptClick}>Accept</ActionButton>
      </ButtonsWrapper>
    </CookiesWrapper>
  );
};

const CookiesWrapper = styled.div`
  ${({ theme: { Consts, Colors } }) => css`
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: ${Colors.cookiesWrapperBackgroundColor};
    width: 350px;
    padding: 18px;
    z-index: 999;
    transition: all 0.2s;

    @media (max-width: ${Consts.tabletBreakPoint}) {
      width: calc(100% - 40px);
    }

    > h4 {
      font-size: 12px;
      line-height: 18px;
      color: ${Colors.cookiesWrapperColor};
    }

    > p {
      font-size: 12px;
      line-height: 18px;
      color: ${Colors.cookiesWrapperColor};

      span {
        font-size: 12px;
        line-height: 18px;
        display: inline;
        cursor: pointer;
        text-decoration: underline;
        color: ${Colors.cookiesWrapperColor};
      }
    }
  `}
`;

const ButtonsWrapper = styled.div`
  ${({ theme: { Colors } }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    margin-top: 30px;

    > button {
      height: 50px;
      width: 83px;
      font-size: 12px;
      min-width: 0;
      background-color: ${Colors.cookiesWrapperColor};
      color: ${Colors.cookiesWrapperBackgroundColor};
      margin-right: 18px;
      margin-bottom: 0;

      &:nth-child(1) {
        background-color: ${Colors.cookiesWrapperBackgroundColor};
        color: ${Colors.cookiesWrapperColor};
        border: 1px solid ${Colors.cookiesWrapperColor};
      }
    }
  `}
`;
